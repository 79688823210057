import React from 'react'
import logoLine from '../../content/images/LOGO-LIGNE.svg'
import logoTree from '../../content/images/LOGOBLANC-VERT.svg'
import logoPeople from '../../content/images/LOGOVERT-PERSONNAGE.svg'

/* export default () => (
    <div
      style={{
        backgroundImage: `url(${logo})`,
        height: '45px',
        width: '20vw',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        marginLeft: '0',
        paddingLeft: '0',
      }}
    />
  ) */

const logos = {
  line: logoLine,
  tree: logoTree,
  people: logoPeople
}

export default ({type, height = '3rem', ...rest}) => {
  const { [type]: logo = logoLine } = logos 
  return (
    <img src={logo} alt='logo' style={{
      height: `${height}`
    }} {...rest}/>
  )
}