import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { IoLogoFacebook } from 'react-icons/io'
import { FaInstagram } from 'react-icons/fa'
import { Link, useStaticQuery, graphql} from 'gatsby'
import Logo from './logo'
import Img from 'gatsby-image'

export default () => {

    const data = useStaticQuery(graphql`
        query FooterQuery {
            fox:file(relativePath: {eq: "images/renard.png"}) {
                childImageSharp {
                    fixed(width: 60) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
    `)

    return (
        <footer id="ledlav-footer" className="pt-3">
            <Container>
                <Row>
                    <Col md={4} xs={12} className="align-self-center">
                        <div className="footer-padding">
                            <Row noGutters className="align-self-center">
                                <div className="pr-2">
                                <Img fixed={data.fox.childImageSharp.fixed} alt="Renard"/>
                                </div>
                                <div>
                                <h6 className="text-center text-uppercase font-weight-bold">Adresse</h6>
                                <address className="text-center">
                                    <strong>Les Enfants de l'Arbre Vert</strong><br />
                                    106 avenue Philippe Auguste, 75011 Paris
                                </address>
                                </div>
                            </Row>
                            
                        </div>
                    </Col>
                    <Col md={4} xs={12} className="align-self-center">
                        <div className="footer-padding">
                            <div className="logo mx-auto">
                                <Logo type="people" alt="Logo blanc et vert" height=''/>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} xs={12} className="align-self-center">
                        <div className="footer-padding">
                            <h6 className="text-center text-uppercase font-weight-bold">Réseaux sociaux</h6>
                            <ul className="list-unstyled list-inline text-center">
                                <li className="list-inline-item">
                                    <a href="https://www.instagram.com/lesenfantsdelarbrevert/"> <FaInstagram size="2rem"/></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.facebook.com/Les-Enfants-de-lArbre-Vert-123374695732671/"> <IoLogoFacebook size="2rem"/></a>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-padding">
                            <h6 className="text-center text-uppercase font-weight-bold">Email</h6>
                            <p className="text-center">
                                <a href="mailto:contact@lesenfantsdelarbrevert.fr">contact@lesenfantsdelarbrevert.fr</a>
                            </p>
                        </div>
                        <div className="mx-auto text-center my-1">
                            <Link to="/cgv" className="text-center">Conditions générales de vente</Link>
                        </div>
                    </Col>
                </Row>
                
            </Container>

        </footer>
    )
}

